<div *ngIf="user.email" class="d-flex align-items-center header">
  <a class="logo position-relative" routerLink="/" data-id="root-logo" showBuildVersionOnHover>
    <ng-container *featureToggleOn="RdrFeature.xmas">
      <img class="image" src="/assets/svg/logo-xmas.svg" />
    </ng-container>

    <ng-container *featureToggleOff="RdrFeature.xmas">
      <img class="image" src="/assets/svg/logo.svg" />
    </ng-container>

    <typography-v2 *ngIf="env" class="text-white position-absolute env" look="small"
      >{{ env }}</typography-v2
    >
  </a>

  <div class="d-flex me-auto align-items-center">
    <ng-container *ngFor="let item of links">
      <ng-container *ngIf="item.featureToggleOff">
        <ng-container *featureToggleOff="item.featureToggleOff">
          <ng-container *ngTemplateOutlet="linkTemplate; context: { item }"></ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="item.featureToggleOn">
        <ng-container *featureToggleOn="item.featureToggleOn">
          <ng-container *ngTemplateOutlet="linkTemplate; context: { item }"></ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!item.featureToggleOff && !item.featureToggleOn">
        <ng-container *ngTemplateOutlet="linkTemplate; context: { item }"></ng-container>
      </ng-container>
    </ng-container>

    <ng-container *showForRole="[UserType.SalesTeamLead]">
      <ng-container *ngIf="user.salesDirector">
        <a
          [routerLink]="'/users'"
          data-id="user-management-sales-dir"
          routerLinkActive
          #link="routerLinkActive"
        >
          <button-v2 type="header" look="link" [pressed]="link.isActive">
            <typography-v2 look="caption-bold">Users</typography-v2>
          </button-v2>
        </a>
      </ng-container>
    </ng-container>

    <ng-template #linkTemplate let-item="item">
      <a
        [routerLink]="item.link"
        [attr.data-id]="item.label | toDataID"
        [queryParams]="item.queryParams"
        [routerLinkActiveOptions]="{ exact: false }"
        routerLinkActive
        #link="routerLinkActive"
      >
        <button-v2 type="header" look="link" [pressed]="link.isActive">
          <typography-v2 look="caption-bold">{{ item.label }}</typography-v2>
        </button-v2>
      </a>
    </ng-template>
  </div>

  <a
    *showForRole="[UserType.SalesAgent, UserType.SalesTeamLead]"
    [routerLink]="'contest'"
    class="contest-button me-3"
  >
    <rdr-svg-icon key="contets-star-v-2" class="star"></rdr-svg-icon>
    Sales Contest 2025
  </a>

  <global-search
    *showForRole="[UserType.SalesAgent, UserType.SalesTeamLead, UserType.TicketingAgent, UserType.Accounting]"
  ></global-search>

  <alerts class="v2-ml-1"></alerts>

  <user-navbar [user]="user" class="v2-ml-1">
    <ng-container *featureToggleOn="RdrFeature.twilio_service">
      <ng-container *ngIf="availableStatus$ | async as availableStatus">
        <dropdown-item-v2
          class="reconnecting"
          *ngIf="availableStatus === AvailableStatus.Reconnecting"
          id="status-reconnecting"
        >
          <div class="d-flex flex-column">
            <typography-v2 look="small" class="text-coral"
              >Oops! It seems the system is not syncing.</typography-v2
            >
            <div class="d-flex align-items-center justify-content-between">
              <typography-v2 look="small" class="text-coral">Connection...</typography-v2
              ><three-dots-loader></three-dots-loader>
            </div>
          </div>
        </dropdown-item-v2>

        <dropdown-item-v2
          *ngIf="availableStatus === AvailableStatus.Available || availableStatus === AvailableStatus.Reconnecting  "
          (click)="stopCalls()"
          class="text-black"
          id="status-unavailable"
          hoverClass
        >
          <rdr-svg-icon key="status-away" class="v2-mr-2"></rdr-svg-icon>
          Be Away
        </dropdown-item-v2>

        <dropdown-item-v2
          *ngIf="availableStatus === AvailableStatus.Unavailable"
          (click)="startCalls()"
          class="text-black"
          id="status-available"
          hoverClass
        >
          <rdr-svg-icon key="status-online" class="v2-mr-2"></rdr-svg-icon>
          Be Active
        </dropdown-item-v2>
      </ng-container>
    </ng-container>

    <dropdown-item-v2
      *ngIf="(user.isActive && user.superadmin) || user.isSuperadminContext"
      (click)="goToProfile()"
      class="text-black"
      id="profile"
      hoverClass
    >
      <rdr-svg-icon key="user-v-2" class="v2-mr-2"></rdr-svg-icon>
      Profile
    </dropdown-item-v2>

    <dropdown-item-v2 (click)="photoUpload.click()" class="text-black" id="photo" hoverClass>
      <rdr-svg-icon key="image" class="v2-mr-2"></rdr-svg-icon>
      Upload Photo
    </dropdown-item-v2>

    <dropdown-item-v2 (click)="logout()" class="text-black" id="logout" hoverClass>
      <rdr-svg-icon key="logout" class="v2-mr-2"></rdr-svg-icon>
      Logout
    </dropdown-item-v2>
  </user-navbar>

  <loading-overlay *ngIf="loading$ | async"></loading-overlay>
</div>

<input (change)="setPhoto($event)" #photoUpload type="file" class="d-none" />
